/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useDispatch } from 'react-redux';
import mbpLogger from 'mbp-logger';
import {
    object, shape, arrayOf, string,
} from 'prop-types';

import RedirectWithStatus from '../GraphqlCommonComponents/RedirectWithStatus/RedirectWithStatus';
import CoreBrowsingBuilder from './CoreBrowsingBuilder';

import SiteLoader from '../../Checkout/containers/Common/SiteLoader/SiteLoader';
import { emitCustomTrackingEvent } from '../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import CategoryPageSkeleton from '../GraphqlSkeletonComponents/CategoryPageSkeleton/CategoryPageSkeleton';
import useSalesforcePersonalizedContentQuery from '../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import useClickStreamCustomEventsTracking from '../../../helpers/Personalization/useClickstreamCustomEventsTracking';

const CoreBrowsingRedirectContainer = ({
    page, brand, transition, pathname,
}) => {
    const dispatch = useDispatch();

    const brandName = brand?.domain || '1800flowers';
    const interactionName = `${brandName} - Get Campaign - Redirect Test`;

    const {
        loading, error, data, variables,
    } = useSalesforcePersonalizedContentQuery({
        queryName: 'redirectTest',
        interactionName,
        targeting: {
            url: pathname,
        },
    });

    useClickStreamCustomEventsTracking({
        salesforceResponse: data?.contentSF,
        isFireImpression: true,
        page,
    });

    if (loading || variables?.skip) {
        if (transition === 'skeleton') {
            return <CategoryPageSkeleton />;
        }
        return <SiteLoader />;
    }

    if (error) {
        mbpLogger.logError({
            module: 'CoreBrowsingRedirectContainer',
            message: 'Redirect failed .. sending customer to homepage',
            error,
        });
        return <RedirectWithStatus to="/" status="301" />;
    }

    if (data) {
        const { userGroup = '', variantURL = '' } = data?.contentSF?.campaign?.campaignResponses?.[0]?.payload || {};

        if (userGroup) {
            if (userGroup.toLowerCase() !== 'control') {
                const params = window?.location?.search;
                return <RedirectWithStatus to={`${variantURL}${params}`} status="200" />;
            }
        }
    }
    return <CoreBrowsingBuilder brand={brand} pageData={page} />;
};

CoreBrowsingRedirectContainer.propTypes = {
    page: shape({
        path: string.isRequired,
        bannerCode: shape({}).isRequired,
        vanity: shape({
            url: string.isRequired,
        }),
        redirectTesting: arrayOf(shape({
            matchingParameters: arrayOf(string),
            redirects: arrayOf(shape({
                variant: string,
                url: string,
                code: string,
                contentType: string,
            })),
        })),
    }),
    transition: string,
    brand: object.isRequired,
    pathname: string,
};

CoreBrowsingRedirectContainer.defaultProps = {
    page: {
        vanity: { url: '' },
        redirectTesting: {
            variant: '',
            url: '',
            code: '',
            contentType: '',
        },
    },
    transition: 'siteLoader',
    pathname: '',
};

export default CoreBrowsingRedirectContainer;
